<template>
    <div class="main-box">
        <Header />
        <div class="main-contain">
            <router-view></router-view>
        </div>
        <div class="terms">
            <span v-if="$store.getters.isEnglish">
                <a href="/terms/EULA" target="blank">EULA</a>
                <a href="/terms/PrivacyPolicy" target="blank">Privacy Policy</a>
            </span>
            <span v-else>
                <a href="/terms/EULA" target="blank">用户协议</a>
                <a href="/terms/PrivacyPolicy" target="blank">隐私政策</a>
            </span>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Header from '@/views/layout/Header.vue'
export default defineComponent({
    name: 'mainBox',
    components: {
        Header
    }
    // if (!userData) {
    //     Storage.localRemove('refreshToken')
    //     Storage.localRemove('accessToken')
    // }
})
</script>

<style lang="less" scope>
.main-box {
    height: 100%;
    width: 100%;
    min-width: 1500px;
    background-color: #f7f7f7;
    .main-contain {
        height: calc(100% - 80px);
        margin-top: 10px;
        overflow: auto;
    }
    .terms {
        position: absolute;
        bottom: 0px;
        width: 100%;
        min-width: 1500px;
        text-align: center;
        height: 40px;
        line-height: 40px;
        background: #f7f7f7;
        a {
            margin: 10px 40px;
            color: #333333;
            text-decoration: underline;
        }
    }
}
</style>
